import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { FaSearch } from 'react-icons/fa';
import './Search.css';
import { useHistory, useLocation } from 'react-router-dom';

function Search() {
    const history = useHistory();
    const location = useLocation();
    const [place, setPlace] = useState('Maui'); // Default value is 'Maui'
    const [category, setCategory] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [hourStart, setHourStart] = useState(null);
    const [hourEnd, setHourEnd] = useState(null);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Function to format the date into YYYY-MM-DD
    function formatDate(date) {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }

    // Parse date from string in the format YYYY-MM-DD and return a valid Date object
    function parseDateString(dateString) {
        if (dateString) {
            const datesArray = dateString.split(','); // Dates are separated by '%2C'
            const firstDateString = datesArray[0]; // Get the first date part in YYYY-MM-DD format
            const [year, month, day] = firstDateString.split('-'); // Split by '-'

            // Create a new Date object (Month is 0-based in JavaScript Date)
            const parsedDate = new Date(year, month - 1, day);

            // Check if the date is valid
            if (!isNaN(parsedDate.getTime())) {
                return parsedDate;
            } else {
                console.error(`Invalid date parsed from URL: ${firstDateString}`);
            }
        }
        return null;
    }

    useEffect(() => {
      const params = new URLSearchParams(location.search);
  
      // Set category from URL if available
      const pubCategoryParam = params.get('pub_category') || params.get('cat');
      if (pubCategoryParam) {
        setCategory(pubCategoryParam);
      }
  
      // Set date from URL if available
      const datesParam = params.get('dates');
      if (datesParam) {
          const parsedDate = parseDateString(datesParam);
          if (parsedDate) {
              setSelectedDate(parsedDate);
          } else {
              console.error('Failed to parse date from URL:', datesParam);
          }
      }
      // Set place based on the 'address' param
      const locationParam = params.get('address') || params.get('location');
      if (locationParam) {
          switch (true) {
              case locationParam.startsWith('Maui'):
                  setPlace('Maui');
                  break;
              case locationParam.startsWith('San Diego'):
                  setPlace('San Diego');
                  break;
              case locationParam.startsWith('New Orleans'):
                  setPlace('New Orleans');
                  break;
              case locationParam.startsWith('Oahu'):
                  setPlace('Oahu');
                  break;
              default:
                  setPlace('Maui'); // Default to Maui if no match
                  break;
          }
      } else {
          // Set place based on URL path if 'address' param is missing
          if (location.pathname.includes('san-diego')) {
              setPlace('San Diego');
          } else if (location.pathname.includes('new-orleans')) {
              setPlace('New Orleans');
          } else if (location.pathname.includes('oahu')) {
            setPlace('Oahu');
          } else {
              setPlace('Maui'); // Default to Maui
          }
      }
      const startHourParam = params.get('hourStart');
      if (startHourParam) {
          setHourStart(parseInt(startHourParam, 10)); // Ensure it's an integer
      }
  
      const endHourParam = params.get('hourEnd');
      if (endHourParam) {
          setHourEnd(parseInt(endHourParam, 10)); // Ensure it's an integer
      }

    }, [location.pathname, location.search]);

    // Handle redirection based on the selected place
    const handlePlaceChange = (newPlace) => {
        setPlace(newPlace);

        let basicUrl = '/'; // Default to root URL

        if (newPlace === 'San Diego') {
            basicUrl = '/p/san-diego';
        } else if (newPlace === 'New Orleans') {
            basicUrl = '/p/new-orleans';
        } else if (newPlace === 'Oahu') {
            basicUrl = '/p/oahu';
        } else if (newPlace === 'Maui') {
            basicUrl = '/'; // For Maui, go to the root URL
        }

        history.push(basicUrl); // Redirect based on the selected place
    };

    // Handle search button click
    const handleSearchClick = () => {
        let address = '';
        let bounds = '';

        // Set address and bounds based on selected place
        if (place === 'San Diego') {
            address = 'San Diego, California, USA';
            bounds = '32.82424061179694,-116.981246782539,32.53415646729198,-117.3157390711059';
        } else if (place === 'New Orleans') {
            address = 'New Orleans, Louisiana, USA';
            bounds = '30.127224413279745,-89.74677278928726,29.86415568231419,-90.14136401517668';
        } else if (place === 'Maui') {
            address = 'Maui, Hawaii, USA';
            bounds = '21.03141298976477,-155.9790420834986,20.57457850828407,-156.6969232882344';
        } else if (place === 'Oahu') {
            address = 'Oahu, Hawaii, USA';
            bounds = '21.71267094221349,-157.6487030918256,21.2548376820431,-158.2817536697334';
        }

        let basicUrl = `/s?sort=meta_reviewsAverage`;

        basicUrl += `&address=${encodeURIComponent(address)}&bounds=${bounds}`;
    
        if (category) {
            basicUrl += `&pub_category=${encodeURIComponent(category)}`;
        }

        if (selectedDate) {
            const formattedDate = formatDate(selectedDate);
            basicUrl += `&dates=${formattedDate}%2C${formattedDate}`;
        }

        if (hourStart !== null && hourStart !== "") {
            basicUrl += `&hourStart=${hourStart}`;
        }
        if (hourEnd !== null && hourEnd !== "") {
            basicUrl += `&hourEnd=${hourEnd}`;
        }

        history.push(basicUrl); // Push the new URL with the search parameters
    };

    const renderCategoryOptions = () => {
        if (place === 'San Diego' || place === 'New Orleans' || place === 'Oahu') {
            return (
                <>
                    <option value="">Select a category</option>
                    <option value="babysitter">Babysitter</option>
                    <option value="tutti-helper">Tutti Helper</option>
                </>
            );
        } else {
            return (
                <>
                    <option value="">Select a category</option>
                    <option value="tutti-helper">Tutti Helper</option>
                    <option value="babysitter">Babysitter</option>
                    <option value="activities">Activities</option>
                    <option value="private-chef">Chefs and Fresh Produce </option>
                    <option value="health_beauty">Health & Beauty</option>
                    <option value="concierge">Vacation Coordinator</option>
                    <option value="rentals">Rentals</option>
                    <option value="photographer">Photography</option>
                </>
            );
        }
    };

    const generateHourOptions = (minHour = 1) => {
        const hours = [];
        hours.push(<option key="none" value="">Select Time</option>); // Default empty option
        for (let i = minHour; i <= 23; i++) {
            hours.push(
                <option key={i} value={i}>
                    {i === 12 ? '12 PM' : i > 12 ? `${i - 12} PM` : `${i} AM`}
                </option>
            );
        }
        return hours;
    };

    const CustomTimeInput = ({ onChange }) => {
        return (
            <div className="time-picker-container">
                <label>Start Time</label>
                <select
                    value={hourStart || ""}
                    onChange={(e) => {
                        const value = e.target.value ? parseInt(e.target.value) : null;
                        setHourStart(value);
                        if (hourEnd && value && hourEnd <= value) {
                            // Reset end hour if it is less than or equal to start hour
                            setHourEnd(null);
                        }
                    }}
                >
                    {generateHourOptions(1)} {/* Start options from 1 */}
                </select>
    
                <label>End Time</label>
                <select
                    value={hourEnd || ""}
                    onChange={(e) => {
                        const value = e.target.value ? parseInt(e.target.value) : null;
                        setHourEnd(value);
                        
                    }}
                    disabled={!hourStart} // Disable end hour until start hour is selected
                >
                {generateHourOptions(hourStart + 1)} {/* Start options after selected start hour */}
                </select>
            </div>
        );
    };

    return (
        <section className="search-section">
            {/* All fields in a row */}
            <div className="search-row">
                {/* Place dropdown */}
                <select
                    value={place}
                    onChange={(e) => handlePlaceChange(e.target.value)}
                    className="search-category"
                >
                    <option value="Maui">Maui</option>
                    <option value="Oahu">Oahu, HI</option>
                    <option value="San Diego">San Diego</option>
                    <option value="New Orleans">New Orleans</option>
                </select>

                {/* Category dropdown */}
                <select
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    className="search-category"
                >
                    {renderCategoryOptions()}
                </select>

                {/* Date picker */}
                <DatePicker
                    selected={selectedDate} // Use the selected date object directly
                    onChange={(date) => setSelectedDate(date)} // Set the date as a Date object
                    dateFormat="MMMM d, yyyy"
                    placeholderText="Select a date"
                    minDate={today}
                    customTimeInput={<CustomTimeInput />} // Custom input for time selection
                    showTimeInput={true}
                    shouldCloseOnSelect={false}
                    timeInputLabel={''}
                />

                {/* Search button */}
                <button className="search-button" onClick={handleSearchClick}>
                    <FaSearch />
                </button>
            </div>
        </section>
    );
}

export default Search;